import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { selectNotification, selectSliderMenuLocked } from '../../store/selectors/app';
import { hasInProgressUpload } from '../../store/selectors/uppy';
import { selectLoggedInUser, selectIsAuthenticated } from '../../store/selectors/authentication';
import { notificationShown, toggleSliderMenuLock } from '../../store/actions/app';
import { logoutUser, switchCompany } from '../../store/thunks/authentication';

import SideBar from './SideBar';
import Notification from './Notification';
import LoggedUserMenu from './LoggedUserMenu';
import UserCompanyMenu from './UserCompanyMenu/UserCompanyMenu';
import Notifications from '../notifications/NotificationsContainer';
import PermissionContext from '../../pages/_permission';
import RemainingTime from './RemainingTime';
import Environment from './Environment';
import Breadcrumbs from './Breadcrumbs';
import ContentPlaceholder from './ContentPlaceholder';

import BbfcMenuItem from '../common/bbfc-components/MenuItem';
import { MENU_ITEMS } from '../../utils/layout';
import { useStartNotificationPolling } from '../../hooks/useStartNotificationPolling';

const DRAWER_WIDTH_OPEN = 200;
const DRAWER_WIDTH_CLOSED = 65;

const Layout = ({ withSliderMenu, withTitle, withBreadcrumbs, children, title, secondaryTitle, breadcrumbs, actions, permissions, contentPlaceholder, sideMenu, className }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { hasPermissions } = useContext(PermissionContext);
  const [placeholder, setPlaceholder] = useState(null);

  const notification = useSelector(selectNotification);
  const sliderMenuLocked = useSelector(selectSliderMenuLocked);
  const loggedInUser = useSelector(selectLoggedInUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const hasInprogressUpload = useSelector(hasInProgressUpload);
  const [marginLeft, setMarginLeft] = useState(0);

  useStartNotificationPolling();

  const menuItems = MENU_ITEMS.filter((item) => {
    if (!item.permissions) return true;
    return hasPermissions(item.permissions);
  });

  useEffect(() => {
    setPlaceholder(contentPlaceholder);

    if (permissions) {
      const hasPermission = hasPermissions(permissions);
      if (!hasPermission) setPlaceholder('unauthorised');
    }
  }, [contentPlaceholder, hasPermissions, permissions]);

  useEffect(() => {
    if (!withSliderMenu) {
      setMarginLeft(0);
      return;
    }
    if (sliderMenuLocked) {
      setMarginLeft(DRAWER_WIDTH_OPEN);
    } else {
      setMarginLeft(DRAWER_WIDTH_CLOSED);
    }
  }, [withSliderMenu, sliderMenuLocked]);

  return (
    <Box className={className}>
      <CssBaseline />
      {withSliderMenu && (
        <SideBar isLocked={sliderMenuLocked} toggleLock={() => dispatch(toggleSliderMenuLock())}>
          <List className="MenuItemList" disablePadding>
            {menuItems.map((item) => (
              <BbfcMenuItem
                key={item?.label}
                label={item?.label}
                IconComponent={item?.IconComponent}
                href={item?.href}
                target={item?.target}
                external={item?.external}
                active={item?.paths?.includes(router.pathname)}
                sliderMenuLocked={sliderMenuLocked}
              />
            ))}
          </List>
        </SideBar>
      )}
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <AppBar position="fixed" sx={{ zIndex: 1100 }}>
          <Toolbar sx={{ bgcolor: 'bbfcTheme.palette.green.main' }}>
            <Box sx={{ mr: 2 }}>
              <img src="/assets/images/logos/bbfc-logo-l-w.png" width={158} height={32} />
            </Box>

            <Environment />

            <Box sx={{ flexGrow: 1 }} />

            {isAuthenticated && <RemainingTime />}
            {isAuthenticated && <Notifications />}
            {isAuthenticated && (
              <UserCompanyMenu
                onChange={(companyId) => dispatch(switchCompany(companyId))}
                loggedInUser={loggedInUser}
              />
            )}
            {isAuthenticated && (
              <LoggedUserMenu
                preventLogout={hasInprogressUpload}
                onLogout={(message) => dispatch(logoutUser(message))}
                loggedInUser={loggedInUser}
              />
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Fade in>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            ml: `${marginLeft}px`,
            mt: ['48px', '56px', '64px'],
            p: 3,
          }}
        >
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            {withTitle && (
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h5" color="secondary.light">
                  <b>{title}</b>
                </Typography>
                {secondaryTitle}
              </Stack>
            )}
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              {actions}
            </Stack>
          </Stack>
          {withBreadcrumbs && <Breadcrumbs items={breadcrumbs} />}
          {placeholder && <ContentPlaceholder variant={placeholder} />}
          {sideMenu && sideMenu}
          {!placeholder && children}
        </Box>
      </Fade>
      <Notification
        notification={notification}
        done={notificationShown}
      />
    </Box>
  );
};

Layout.defaultProps = {
  withSliderMenu: true,
  withTitle: true,
  withBreadcrumbs: true,
  children: null,
  title: null,
  secondaryTitle: null,
  breadcrumbs: [],
  actions: null,
  contentPlaceholder: null,
  sideMenu: null,
};

Layout.propTypes = {
  withSliderMenu: PropTypes.bool,
  withTitle: PropTypes.bool,
  withBreadcrumbs: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  title: PropTypes.string,
  secondaryTitle: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
    }).isRequired
  ),
  actions: PropTypes.object,
  contentPlaceholder: PropTypes.oneOf(['error', 'loading', 'unauthorised', 'notfound']),
  sideMenu: PropTypes.node,
};

export default Layout;
